import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';
import ConditionalWrapper from './conditional-wrapper';
import Down from '../svg/down.svg';

export default function FullImage({ text, noDescription, includeTitle, image, link, noMargin, className }) {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  setTimeout(() => {
    setShow(true);
  }, 2000);

  return (
    <div
      ref={ref}
      className={className}
      css={css`
        position: relative;
        margin-bottom: ${(noMargin) ? 0 : 'calc(var(--margin) * 2)'};
        opacity: ${(show) ? 1 : 0};
        transition: opacity 0.6s var(--curve);
      `}
    >
      <div
        css={css`
          position: relative;
          height: 100vh;

          @media (max-width: 700px) {
            height: 75vh;

            img {
              height: 75vh !important;
            }
          }
        `}
      >
        {image?.asset && <Image
          {...image}
          width={1980}
          style={{
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
          }}
          loading="eager"
          onLoad={() => setShow(true)}
        />}
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: var(--gutter);
            position: absolute;
            top: var(--margin);
            right: var(--margin);
            left: var(--margin);
          `}
        >
          <h1
            className="type--70"
            css={css`
              grid-column: span 10;
              color: var(--white);
              text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);

              @media (max-width: 1080px) {
                grid-column: span 12;
              }

              a {
                color: var(--white);
                text-decoration: none;
                transition: color 0.6s var(--curve);

                &:hover {
                  color: var(--primary);
                  transition: color 0s;
                }
              }
            `}
          >
            <ConditionalWrapper
              condition={link}
              wrapper={children => <Link to={link}>{children}</Link>}
            >
              {includeTitle && <Link to="/">The&nbsp;Substation/</Link>}&#x200b;{text}
            </ConditionalWrapper>
          </h1>
        </div>
        <div
          css={css`
            position: absolute;
            right: var(--margin);
            bottom: var(--gutter);
            left: var(--margin);
            text-align: center;

            @media (max-width: 1080px) {
              bottom: calc(var(--gutter) * 2);
            }

            @media (max-width: 700px) {
              display: none;
            }
          `}
        >
          <Down
            css={css`
              cursor: pointer;

              &:hover {
                path {
                  fill: var(--primary);
                  transition: fill 0s;
                }
              }

              path {
                transition: fill 0.6s var(--curve);
              }

            `}
            onClick={() => {
              if (window && ref?.current) {
                const rect = ref.current.getBoundingClientRect();
                window.scrollTo({
                  top: rect.height + rect.top + window.scrollY,
                  behavior: 'smooth',
                })
              }
            }}
          />
        </div>
      </div>
      {(!noDescription && image?.asset?.description) && <div
        className="type--15"
        css={css`
          background-color: var(--white);
          padding: var(--gutter) var(--margin);
        `}
      >{image?.asset?.description}
      </div>}
    </div>
  )
}
